<template>
  <div class="">
    <van-nav-bar left-arrow title='详情' fixed @click-left='$router.go(-1)' />
    <van-collapse v-model="activeNames" accordion style='margin-top: 55px'>
      <div class="splitTitle">{{ $t('logistics.基本信息') }}</div>
      <van-collapse-item :title="$t('logistics.基本信息')" name="1">
        <div class="items">
          <h5>{{ $t('logistics.账套') }}</h5>
          <p>{{ ruleForm.orgName }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('logistics.状态') }}</h5>
          <p>{{ ruleForm.orderStatus | setDict('BOOKING_STATUS') }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('logistics.业务类型') }}</h5>
          <p>{{ ruleForm.businessType | setDict('booking_business_type') }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('logistics.订舱通知编号') }}</h5>
          <p>{{ ruleForm.bookingNo }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('logistics.申请人') }}</h5>
          <p>{{ ruleForm.applyUserName }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('logistics.申请部门') }}</h5>
          <p>{{ ruleForm.applyDeptName }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('logistics.完货时间') }}</h5>
          <p>{{ ruleForm.completionTime }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('logistics.运输方式') }}</h5>
          <p>{{ ruleForm.modeTransport | setDict('MODE_TRANSPORT') }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('logistics.运输条款') }}</h5>
          <p>{{ ruleForm.transportationConditions | setDict('transportation_conditions_type') }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('logistics.起运港') }}</h5>
          <p>{{ ruleForm.shipmentPortName }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('logistics.卸货港') }}</h5>
          <p>{{ ruleForm.destinationPortName }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('logistics.目的地') }}</h5>
          <p>{{ ruleForm.destination }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('logistics.装运方式') }}</h5>
          <p>{{ ruleForm.modeShipment | setDict('modeShipmentType') }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('logistics.货物性质') }}</h5>
          <p>{{ ruleForm.goodsNature | setDict('goodsNatureType') }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('logistics.国内运输方式') }}</h5>
          <p>{{ ruleForm.domesticModeTransport | setDict('domesticModeTransportType') }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('logistics.毛重') }}</h5>
          <p>{{ ruleForm.grossWeight }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('logistics.净重') }}</h5>
          <p>{{ ruleForm.netWeight }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('logistics.体积') }}</h5>
          <p>{{ summaryvolume }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('logistics.件数') }}</h5>
          <p>{{ ruleForm.number | formatAmount(6,false) }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('logistics.柜量') }}</h5>
          <p>{{ ruleForm.cabinetQuantity | formatAmount(6,false) }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('logistics.发货总金额') }}</h5>
          <p>{{ summaryContractAmount | formatAmount }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('logistics.船期要求') }}</h5>
          <p>{{ ruleForm.sailingDateRequirement }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('logistics.装运点') }}</h5>
          <p>{{ ruleForm.shippingPoint }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('logistics.检疫费用') }}</h5>
          <p>{{ ruleForm.quarantineFee | formatAmount }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('logistics.检疫费用币种') }}</h5>
          <p>{{ ruleForm.quarantineCurrency | setDict('CURRENCY_TYPE') }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('logistics.发货人') }}</h5>
          <p>{{ ruleForm.shipperName }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('logistics.收货人') }}</h5>
          <p>{{ ruleForm.consigneeName }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('logistics.其他要求') }}</h5>
          <p>{{ ruleForm.otherRequirement }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('logistics.运管专员') }}</h5>
          <p>{{ ruleForm.operationSpecialistName }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('logistics.备注') }}</h5>
          <p>{{ ruleForm.remarks }}</p>
        </div>
      </van-collapse-item>
      <van-collapse-item :title="$t('logistics.订舱信息登记')" name="2" v-if="ruleForm.status === 'APPROVED' && (this.$route.query.action ==='register' || ruleForm.isRecord==='Y') ">
        <div class="items">
          <h5>{{ $t('logistics.船名') }}</h5>
          <p>{{ ruleForm.shipName }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('logistics.船次') }}</h5>
          <p>{{ ruleForm.shipNumber }}</p>
        </div>
        <div class="items">
          <h5>BOOKINGnumber</h5>
          <p>{{ ruleForm.bookingNumber }}</p>
        </div>
        <div class="items">
          <h5>ETD</h5>
          <p>{{ ruleForm.etd }}</p>
        </div>
        <div class="items">
          <h5>ETA</h5>
          <p>{{ ruleForm.eta }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('logistics.航班号') }}</h5>
          <p>{{ ruleForm.flightNumber }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('logistics.车号') }}</h5>
          <p>{{ ruleForm.licenseNumber }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('logistics.提单号') }}</h5>
          <p>{{ ruleForm.blNo }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('logistics.开船日期') }}</h5>
          <p>{{ ruleForm.blDate }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('logistics.货代名称') }}</h5>
          <p>{{ ruleForm.freightForwarder }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('logistics.实际承运人') }}</h5>
          <p>{{ ruleForm.actualCarrier }}</p>
        </div>
        <div class="items">
          <h5>ATD</h5>
          <p>{{ ruleForm.atd }}</p>
        </div>
        <div class="items">
          <h5>ATA</h5>
          <p>{{ ruleForm.ata }}</p>
        </div>
      </van-collapse-item>
      <van-collapse-item :title="$t('logistics.柜型柜量')" name="3">
        <div v-if="!ruleForm.logisticsBookingCabinetList || !ruleForm.logisticsBookingCabinetList.length">{{ $t('logistics.无') }}</div>
        <div v-for="(item, index) in this.ruleForm.logisticsBookingCabinetList" :key="index" class="detailLine">
          <div class="items">
            <h5>{{ $t('logistics.序号') }}</h5>
            <p>{{ index + 1 }}</p>
          </div>
          <div class="items">
            <h5>{{ $t('logistics.柜型') }}</h5>
            <p>{{ item.cabinetType | setDict('CABINET_TYPE') }}</p>
          </div>
          <div class="items">
            <h5>{{ $t('logistics.柜量') }}</h5>
            <p>{{ item.cabinetQuantity | formatAmount(6,false) }}</p>
          </div>
          <div class="items">
            <h5>{{ $t('logistics.毛重') }}(KG)</h5>
            <p>{{ item.grossWeight }}</p>
          </div>
          <div class="items">
            <h5>{{ $t('logistics.净重') }}(KG)</h5>
            <p>{{ item.netWeight }}</p>
          </div>
          <div class="items">
            <h5>{{ $t('logistics.体积') }}(CBM)</h5>
            <p>{{ item.volume }}</p>
          </div>
          <van-divider dashed :style="{ borderColor: '#333' }"/>
        </div>
      </van-collapse-item>
      <van-collapse-item :title="$t('logistics.关联采购合同')" name="4" v-if="ruleForm.businessType ==='2'">
        <div v-if="!ruleForm.logisticsBookingContractList || !ruleForm.logisticsBookingContractList.length">{{ $t('logistics.无') }}</div>
        <div v-for="(item, index) in this.ruleForm.logisticsBookingContractList" :key="index" class="detailLine">
          <div class="items">
            <h5>{{ $t('logistics.序号') }}</h5>
            <p>{{ index + 1 }}</p>
          </div>
          <div class="items">
            <h5>{{ $t('logistics.采购合同号') }}</h5>
            <p>{{ item.purContractNo }}</p>
          </div>
          <div class="items">
            <h5>{{ $t('logistics.供应商名称') }}</h5>
            <p>{{ item.purSupplierName }}</p>
          </div>
          <div class="items">
            <h5>{{ $t('logistics.币种') }}</h5>
            <p>{{ item.purSupplierName | setDict('CURRENCY_TYPE') }}</p>
          </div>
          <div class="items">
            <h5>{{ $t('logistics.价格条款') }}</h5>
            <p>{{ item.priceTerms | setDict('PRICE_TERMS') }}</p>
          </div>
          <van-divider dashed :style="{ borderColor: '#333' }"/>
        </div>
      </van-collapse-item>
      <van-collapse-item :title="$t('logistics.关联销售合同')" name="5" v-if="ruleForm.businessType ==='1'">
        <div v-if="!ruleForm.logisticsBookingContractList || !ruleForm.logisticsBookingContractList.length">{{ $t('logistics.无') }}</div>
        <div v-for="(item, index) in this.ruleForm.logisticsBookingContractList" :key="index" class="detailLine">
          <div class="items">
            <h5>{{ $t('logistics.序号') }}</h5>
            <p>{{ index + 1 }}</p>
          </div>
          <div class="items">
            <h5>{{ $t('logistics.销售合同号') }}</h5>
            <p>{{ item.saleContractNo }}</p>
          </div>
          <div class="items">
            <h5>{{ $t('logistics.客户名称') }}</h5>
            <p>{{ item.saleCustomerName }}</p>
          </div>
          <div class="items">
            <h5>{{ $t('logistics.币种') }}</h5>
            <p>{{ item.currency | setDict('CURRENCY_TYPE') }}</p>
          </div>
          <div class="items">
            <h5>{{ $t('logistics.价格条款') }}</h5>
            <p>{{ item.priceTerms | setDict('PRICE_TERMS') }}</p>
          </div>
          <van-divider dashed :style="{ borderColor: '#333' }"/>
        </div>
      </van-collapse-item>

      <div class="splitTitle">{{ $t('logistics.商品明细') }}</div>
      <van-collapse-item :title="$t('logistics.商品明细')" name="6">
        <div v-if="ruleForm.businessType==='2'">
          <div v-if="!ruleForm.commodityDetailList || !ruleForm.commodityDetailList.length">{{ $t('logistics.无') }}</div>
          <div v-for="(item, index) in this.ruleForm.commodityDetailList" :key="index" class="detailLine">
            <div class="items">
              <h5>{{ $t('logistics.序号') }}</h5>
              <p>{{ index + 1 }}</p>
            </div>
            <div class="items">
              <h5>{{ $t('logistics.采购合同号') }}</h5>
              <p>{{ item.purContractNo }}</p>
            </div>
            <div class="items">
              <h5>{{ $t('logistics.到单号') }}</h5>
              <p>{{ item.orderNo }}</p>
            </div>
            <div class="items">
              <h5>{{ $t('logistics.提单号') }}</h5>
              <p>{{ item.blNo }}</p>
            </div>
            <div class="items">
              <h5>{{ $t('logistics.商品编码') }}</h5>
              <p>{{ item.materialCode }}</p>
            </div>
            <div class="items">
              <h5>{{ $t('logistics.商品名称') }}</h5>
              <p>{{ item.commodityDetails }}</p>
            </div>
            <div class="items">
              <h5>{{ $t('logistics.商品大类') }}</h5>
              <p>{{ item.commodityCategoryName }}</p>
            </div>
            <div class="items">
              <h5>{{ $t('logistics.产品描述') }}</h5>
              <p>{{ item.materialDesc }}</p>
            </div>
            <div class="items">
              <h5>{{ $t('logistics.工厂型号') }}</h5>
              <p>{{ item.specification }}</p>
            </div>
            <div class="items">
              <h5>{{ $t('logistics.海关编码') }}</h5>
              <p>{{ item.customsCodeName }}</p>
            </div>
            <div class="items">
              <h5>{{ $t('logistics.退税率') }}(%)</h5>
              <p>{{ item.taxRebateRate ? item.taxRebateRate+'%':'' }}</p>
            </div>
            <div class="items">
              <h5>{{ $t('logistics.第一单位') }}-数量</h5>
              <p>{{ item.firstUnitNumber | formatAmount(6,false) }}</p>
            </div>
            <div class="items">
              <h5>{{ $t('logistics.第一单位') }}-单位</h5>
              <p>{{ item.firstUnitName }}</p>
            </div>
            <div class="items">
              <h5>{{ $t('logistics.第二单位') }}-数量</h5>
              <p>{{ item.secondUnitNumber | formatAmount(6,false) }}</p>
            </div>
            <div class="items">
              <h5>{{ $t('logistics.第二单位') }}-单位</h5>
              <p>{{ item.secondUnitName }}</p>
            </div>
            <div class="items">
              <h5>{{ $t('logistics.转化率') }}</h5>
              <p>{{ item.conversionRate }}</p>
            </div>
            <div class="items">
              <h5>{{ $t('logistics.税率') }}</h5>
              <p>{{ item.tariffRate }}</p>
            </div>
            <div class="items">
              <h5>{{ $t('logistics.单价') }}</h5>
              <p>{{ item.contractUnitPrice | formatAmount(6,false) }}</p>
            </div>
            <div class="items">
              <h5>{{ $t('logistics.原币金额') }}</h5>
              <p>{{ item.originalAmount | formatAmount }}</p>
            </div>
            <div class="items">
              <h5>{{ $t('logistics.本位币金额') }}</h5>
              <p>{{ item.baseAmount | formatAmount }}</p>
            </div>
            <van-divider dashed :style="{ borderColor: '#333' }"/>
          </div>
        </div>
        <div v-else>
          <div v-if="!ruleForm.commodityDetailList || !ruleForm.commodityDetailList.length">{{ $t('logistics.无') }}</div>
          <div v-for="(item, index) in this.ruleForm.commodityDetailList" :key="index" class="detailLine">
            <div class="items">
              <h5>{{ $t('logistics.序号') }}</h5>
              <p>{{ index + 1 }}</p>
            </div>
            <div class="items">
              <h5>{{ $t('logistics.销售合同') }}</h5>
              <p>{{ item.saleContractNo }}</p>
            </div>
            <div class="items">
              <h5>{{ $t('logistics.发货单') }}</h5>
              <p>{{ item.saleDeliveryNo }}</p>
            </div>
            <div class="items">
              <h5>{{ $t('logistics.商品编码') }}</h5>
              <p>{{ item.materialCode }}</p>
            </div>
            <div class="items">
              <h5>{{ $t('logistics.商品名称') }}</h5>
              <p>{{ item.commodityDetails }}</p>
            </div>
            <div class="items">
              <h5>{{ $t('logistics.商品大类') }}</h5>
              <p>{{ item.commodityCategoryName }}</p>
            </div>
            <div class="items">
              <h5>{{ $t('logistics.产品描述') }}</h5>
              <p>{{ item.materialDesc }}</p>
            </div>
            <div class="items">
              <h5>{{ $t('logistics.工厂型号') }}</h5>
              <p>{{ item.specification }}</p>
            </div>
            <div class="items">
              <h5>{{ $t('logistics.仓库') }}</h5>
              <p>{{ item.warehouseName }}</p>
            </div>
            <div class="items">
              <h5>{{ $t('logistics.数量') }}</h5>
              <p>{{ item.firstUnitNumber | formatAmount(6,false) }}</p>
            </div>
            <div class="items">
              <h5>{{ $t('logistics.单位') }}</h5>
              <p>{{ item.firstUnitName }}</p>
            </div>
            <div class="items">
              <h5>{{ $t('logistics.单价') }}</h5>
              <p>{{ item.contractUnitPrice | formatAmount(6,false) }}</p>
            </div>
            <div class="items">
              <h5>{{ $t('logistics.原币金额') }}</h5>
              <p>{{ item.originalAmount | formatAmount }}</p>
            </div>
            <div class="items">
              <h5>{{ $t('logistics.本位币金额') }}</h5>
              <p>{{ item.baseAmount | formatAmount }}</p>
            </div>
            <div class="items">
              <h5>{{ $t('logistics.税率') }}(%)</h5>
              <p>{{ item.tariffRate ? item.tariffRate:'0' }}%</p>
            </div>
            <van-divider dashed :style="{ borderColor: '#333' }"/>
          </div>
        </div>
      </van-collapse-item>

      <div class="splitTitle">{{ $t('logistics.附件') }}</div>
      <van-collapse-item :title="$t('logistics.附件信息')" name="8">
        <div v-if="!attachmentList || !attachmentList.length">{{ $t('logistics.无') }}</div>
        <div v-for="(item, index) in this.attachmentList" :key="index" class="detailLine">
          <div class="items">
            <h5>{{ $t('logistics.序号') }}</h5>
            <p>{{ index + 1 }}</p>
          </div>
          <div class="items">
            <h5>{{ $t('logistics.附件名称') }}</h5>
            <p>{{ item.attachmentName }}</p>
          </div>
          <div class="items">
            <h5>{{ $t('logistics.操作') }}</h5>
            <p>
              <van-icon name="eye-o" color="#19d2fe" size="20" @click="handleFileView(item.id,item.attachmentName)" />
              <!--              <a href="#" @click="handleFileView(item.id,item.attachmentName)">{{ $t('pur.预览') }}</a>-->
              <!--              <a href="#" @click="downloadAttachment(item.id,item.attachmentName)" style="margin-left: 7px">{{ $t('pur.下载') }}</a>-->
            </p>
          </div>
          <div class="items">
            <h5>{{ $t('logistics.上传人') }}</h5>
            <p>{{ item.createName }}</p>
          </div>
          <div class="items">
            <h5>{{ $t('logistics.上传时间') }}</h5>
            <p>{{ item.createTime }}</p>
          </div>
          <van-divider dashed :style="{ borderColor: '#333' }"/>
        </div>
      </van-collapse-item>

      <div class="splitTitle">{{ $t('logistics.系统信息') }}</div>
      <van-collapse-item :title="$t('logistics.系统信息')" name="9">
        <div class="items">
          <h5>{{ $t('logistics.制单日期') }}</h5>
          <p>{{ this.ruleForm.createTime }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('logistics.制单人') }}</h5>
          <p>{{ this.ruleForm.createByName }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('logistics.修改时间') }}</h5>
          <p>{{ this.ruleForm.lastmodifiedTime }}</p>
        </div>
      </van-collapse-item>
    </van-collapse>
    <div style="margin: 8px 0;">
      <my-history-panel ref="historyPanel" :pid="ruleForm.processInstanceId"></my-history-panel>
    </div>
    <div v-if="approvalShow">
      <my-approval
        v-if="ruleForm"
        class="approvalFixed"
        :woCodeValue="woCode"
        :businessInfo="ruleForm"
        :detailPageName="$route.name"
        :customSubmitBtnName="customSubmitBtnName"
        :processName="processInstanceName"
        :taskId="taskInstanceId"
        @processCallback="processCallback"
        @checkBeforeProcess="checkBeforeProcess"
        @beforeSubmitCallBack="beforeSubmitCallBack"
        @closeStartCallback="closeStartCallback"
      >
      </my-approval>
    </div>
  </div>
</template>

<script>
import { Dialog } from 'vant';
import util from '../../../libs/util';
import myHistoryPanel from '@/views/business/components/myHistoryPanel';
import myApproval from '@/views/business/components/myApproval';

export default {
  name: 'visitorFieldComponent',
  components: {
    myHistoryPanel,
    myApproval
  },
  data() {
    return {
      attachmentList: [],
      loading: false,
      finished: false,
      activeNames: '1',
      hostName: '',
      task: '',
      personId: '',
      approvalShow: false,
      customSubmitBtnName: ['同意', '不同意'],
      id: '',
      key: '',
      title: '',
      detail: null,
      active: 0,
      businessKey: '',
      woCode: 'bookingNotice',
      processInstanceName: '订舱变更',
      mappingId: '',
      taskInstanceId: '',
      currentNode: '',
      variables: {},
      taskType: '',
      ruleForm: {
        id: '',
        orgName: '',
        bookingNo: '', // 订舱编号
        businessType: '', // 业务类型
        shipmentPortName: '', // 启运港
        shipmentPortId: '',
        completionTime: '', // 完货时间
        destinationPortName: '', // 目的港
        destinationPortId: '',
        transportationConditions: '', // 运输条件
        modeTransport: '', // 运输方式
        modeShipment: '', // 装运方式
        domesticModeTransport: '', // 国内运输方式
        goodsNature: '', // 货物性质
        cabinetQuantity: '', // 柜量
        grossWeight: '', // 毛重
        netWeight: '', // 净重
        volume: '', // 体积
        sailingDateRequirement: '', // 船期要求
        otherRequirement: '', // 其他要求
        number: '', // 件数
        commodityDetailList: [], // 明细
        shipmentDetailList: [], // 出运情况记录
        logisticsBookingCabinetList: [], // 柜型柜量
        logisticsBookingContractList: [],
        purContractList: [],
        contractAmount: 0, // 发货总金额
        quarantineFee: 0, // 检疫费用
        status: 'PREPARING',
        remarks: '',
        shipName: '', // 船名
        shipNumber: '', // 船次
        bookingNumber: '',
        expectShipDate: '', // 预计船期
        shippingPoint: '', // 装运点
        quarantineCurrency: '', // 检疫币种
        estimatedArrivalDate: '', // 预计到达日期
        flightNumber: '', // 航班号
        shipperName: '', // 发货人
        shipperId: '',
        consigneeName: '', // 收货人
        consigneeId: '',
        finalStatus: 'NOT_END',
        applyDeptName: '',
        applyDeptId: '',
        applyUserName: '',
        applyUserId: '',
        etd: '',
        eta: '',
        licenseNumber: '',
        blNo: '',
        blDate: '',
        isConfirmationRecord: 'N',
        originalBookingNo: '',
        operationSpecialistId: '',
        operationSpecialistName: ''
      },
    };
  },
  props: {},
  computed: {
    // 汇总发货总金额
    summaryContractAmount () {
      let originalAmount = 0;
      this.ruleForm.commodityDetailList.forEach(res => {
        originalAmount += Number((Number(res.originalAmount) || 0).toFixed(2));
      });
      this.ruleForm.contractAmount = originalAmount;
      return originalAmount;
    },
    summaryCabinetQuantity () {
      let cabinetQuantity = 0;
      this.ruleForm.logisticsBookingCabinetList.forEach(res => {
        cabinetQuantity += res.cabinetQuantity ? res.cabinetQuantity : 0;
      });
      this.ruleForm.cabinetQuantity = cabinetQuantity;
      return cabinetQuantity;
    },
    // 毛重
    summarygrossWeight () {
      let grossWeight = 0;
      this.ruleForm.logisticsBookingCabinetList.forEach(res => {
        grossWeight += Number((Number(res.grossWeight) || 0).toFixed(4));
      });
      grossWeight = Number(grossWeight.toFixed(4));
      this.ruleForm.grossWeight = grossWeight;
      return grossWeight;
    },
    // 净重
    summarynetWeight () {
      let netWeight = 0;
      this.ruleForm.logisticsBookingCabinetList.forEach(res => {
        netWeight += Number((Number(res.netWeight) || 0).toFixed(4));
      });
      netWeight = Number(netWeight.toFixed(4));
      this.ruleForm.netWeight = netWeight;
      return netWeight;
    },
    // 体积
    summaryvolume () {
      let volume = 0;
      this.ruleForm.logisticsBookingCabinetList.forEach(res => {
        volume += Number((Number(res.volume) || 0));
      });
      volume = Number(volume.toFixed(4));
      this.ruleForm.volume = volume;
      return volume;
    }
  },
  methods: {
    /**
     * 根据文件id和文件名生成预览链接
     * @param id 附件id
     * @param fileName 附件名
     * @returns {string}
     */
    handleFileView (id, fileName) {
      this.$FilePrvwUtils.openFile(id,fileName);
    },
    downloadAttachment(id, fileName) {
      this.$myHttp({
        method: "post",
        url: "/microarch/sys/sysAttchement/download",
        data: {
          "entity": {
            "id": id
          }
        },
        responseType: "arraybuffer"
      }).then(res => {
        // 成功回调
        var headers = res.headers;
        var blob = new Blob([res.data], {
          type: headers["content-type"]
        });
        var link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = fileName;
        link.click();
      });
    },
    getAttachmentList() {
      this.$myHttp({
        method: "post",
        url: "/microarch/sys/sysAttchement/list",
        data: {
          "entity": {
            "bizId": this.ruleForm.id
          }
        }
      }).then(res => {
        let backData = res.data;
        if (backData) {
          this.attachmentList = backData;
        }
      });
    },
    onLoad() {
      setTimeout(() => {
        for (let i = 0; i < 10; i++) {
          this.ruleForm.importFeePaymentDetailList.push(this.ruleForm.importFeePaymentDetailList.length + 1);
        }
        this.loading = false;
        if (this.ruleForm.importFeePaymentDetailList.length >= 40) {
          this.finished = true;
        }
      }, 500);
    },
    getProcessMappingId() {
      var userInfo = JSON.parse(localStorage.getItem('userInfo'));
      this.$myHttp({
        method: 'post',
        url: '/microarch/activiti/mapping/findBywoCodeAndOrgDept',
        data: {
          entity: {
            woCode: this.woCode,
            orgId: userInfo.companyId,
            deptId: userInfo.deptId
          }
        }
      }).then(res => {
        if (res.data.ext.code === '2000') {
          this.mappingId = res.data.ext.mappingId;
        } else {
          Toast.fail({
            message: res.data.ext.message,
            type: 'error',
            duration: 1500
          });
        }
      });
    },
    backfilleditData(id) {
      this.$myHttp({
        method: 'POST',
        url: '/microarch/pur/logisticsBookingHeader/view',
        data: {
          entity: {
            id: id
          }
        }
      }).then(res => {
        // 成功回调方法
        var data = res.data.entity;
        if (data) {
          this.ruleForm = res.data.entity;
          this.processInstanceName = util.getWorkflowCode(this.ruleForm.paymentApplicationNo, this.processInstanceName);
          this.variables.activitiData = res.data.entity;
          this.getProcessMappingId();
          this.getAttachmentList();
          this.$refs.historyPanel.getData(this.ruleForm.processInstanceId);
          if (this.$route.query.type) {
            this.approvalShow = true;
          }
        }
      });
    },
    setApprovalData(row) {
      if (row && row.pid) {
        this.ruleForm.id = row.businessKey;
        this.taskInstanceId = row.id;
        this.taskType = row.taskType;
      }
    },
    /**
     * 业务提交之前校验
     * */
    checkBeforeProcess(type) {
      if (type) {
        if (type === 'beforeAddsign') {
          this.$refs.demoTemperatureEdit.beforeAddsign();
        } else if (type === 'afterAddsign') {
          this.$refs.demoTemperatureEdit.afterAddsign();
        } else if (type === 'endProcess') {
          this.$refs.demoTemperatureEdit.endProcess();
        } else if (type === 'repelProcess') {
          this.repelProcess();
        } else {
          if (!this.taskInstanceId) {
            var params = {
              businessKey: this.ruleForm.id,
              mappingId: this.mappingId,
              processInstanceName: this.processInstanceName,
              userName: sessionStorage.getItem('userName'),
              variables: this.variables
            };
            this.startProcessAction(params);
          } else {
            this.$refs.demoTemperatureEdit.submit();
          }
        }
      } else {
        Toast.fail({
          message: this.$t('logistics.数据校验失败'),
          type: 'warning'
        });
      }
    },
    // 业务发起流程
    startProcessAction(params) {
      if (params.mappingId) {
        params.mappingId = this.mappingId;
      }
      this.processInstanceName = util.getWorkflowCode(this.ruleForm.paymentApplicationNo, this.processInstanceName);
      this.$myHttp({
        method: 'post',
        url: '/microarch/pur/logisticsBookingHeader/start',
        data: params
      })
        .then(res => {
          var backData = res.data.ext;
          if (backData) {
            this.$refs.demoTemperatureEdit.submitLoading = false;
            this.$refs.demoTemperatureEdit.isStart = true;
            this.$refs.demoTemperatureEdit.form.currentNodeName = backData.taskName;
            this.$refs.demoTemperatureEdit.firstTaskName = backData.taskName;
            this.$refs.demoTemperatureEdit.isFirstNode = true;
            this.$refs.demoTemperatureEdit.taskId = backData.taskId;
            this.$refs.demoTemperatureEdit.processInstanceId = backData.processInstanceId;
            this.$refs.demoTemperatureEdit.transactProcess(backData.taskId, backData.processInstanceId);
          } else {
            Toast.fail({
              message: this.$t('logistics.流程发起异常'),
              type: 'warning'
            });
            this.$refs.demoTemperatureEdit.submitLoading = false;
          }
        })
        .catch(_ => {
          this.$refs.demoTemperatureEdit.submitLoading = false;
        });
    },
    // 业务提交流程
    submitProcessAction(params) {
      this.processInstanceName = util.getWorkflowCode(this.ruleForm.paymentApplicationNo, this.processInstanceName);
      this.$myHttp({
        method: 'post',
        url: '/microarch/pur/logisticsBookingHeader/submit',
        data: params
      })
        .then(res => {
          if (res.data.ext.success) {
            this.processCallback(null, null, this.taskType);
          } else {
            Toast.fail({
              message: res.data.ext.msg,
              type: 'warning'
            });
          }
        })
        .catch(_ => {});
    },
    /***
     * 业务撤销流程
     */
    repelProcess() {
      var _this = this;
      _this
        .$confirm(this.$t('myApprovalBtn.confirmRevocationProcess'), {
          cancelButtonClass: 'sysBackBtn'
        })
        .then(_ => {
          this.$myHttp({
            method: 'post',
            url: '/microarch/pur/logisticsBookingHeader/undoProcess',
            data: {
              entity: {
                id: this.ruleForm.id,
                processInstanceId: this.ruleForm.processInstanceId
              }
            }
          })
            .then(res => {
              if (res.data.success === undefined || res.data.success) {
                Toast.fail({
                  message: this.$t('logistics.撤销成功'),
                  type: 'success'
                });
                this.processCallback(null, null, this.taskType);
              } else {
                Toast.fail({
                  message: res.data.msg,
                  type: 'warning'
                });
              }
            })
            .catch(_ => {});
        })
        .catch(_ => {});
    },

    /***
     * 关闭发起流程审批窗口
     * 删除流程实例，回滚业务状态
     */
    closeStartCallback(pid) {
      this.$myHttp({
        method: 'POST',
        url: '/microarch/pur/logisticsBookingHeader/deleteProcess',
        data: {
          entity: {
            id: this.ruleForm.id,
            processInstanceId: pid
          }
        }
      })
        .then(res => {
          this.$refs.demoTemperatureEdit.dialogVisible = false;
          this.$refs.demoTemperatureEdit.taskId = '';
          this.$refs.demoTemperatureEdit.processInstanceId = '';
          this.processCallback(null, null, this.taskType);
        })
        .catch(_ => {});
    },

    /**
     * 提交之前校验
     * */
    beforeSubmitCallBack(params) {
      // 业务参数校验 此时可以从params中获取提交审批所有的参数
      // 根据这些参数做相应的判断 如果通过调用流程组件中提交方法
      // 不通过给出相应的提示或者触发其他业务操作
      // let selectUserArray = params.candidateUserIds[params.nextId]
      // console.log(selectUserArray);
      this.submitProcessAction(params);
    },
    /**
     * 流程审批之后业务数据更改
     * */
    processCallback(piid, optionType, taskType) {
      var _this = this;
      // util.closeCurrentTag('sale/return/saleReturnApplyEdit', this);
      // // 成功回调方法
      // if (taskType === 'transated') {
      //   _this.$router.push({
      //     name: 'sys/workFlow/haveList'
      //   });
      // } else if (taskType === 'waitfor') {
      //   _this.$router.push({
      //     name: 'sys/workFlow/toDoList'
      //   });
      // } else {
      //   this.$router.push({
      //     name: 'sale/return/saleReturnApplyList'
      //   });
      //   this.$bus.emit('saleReturnApply-SaveSuccess');
      // }
      this.$router.go(-1);
    }
  },
  created() {},
  mounted() {
    let selectRow = JSON.parse(this.$route.query.row);
    if (selectRow) {
      this.ruleForm.id = selectRow.id;
      if (selectRow.businessKey) {
        this.ruleForm.id = selectRow.businessKey;
      }
    }
    if (this.ruleForm.id) {
      this.backfilleditData(this.ruleForm.id);
    }

    if (selectRow) {
      this.setApprovalData(selectRow);
    }
  },
  filters: {
    setDict(v, dictName) {
      return util.setDict(v, dictName);
    },
    formatAmount(v, decimal = 2, isCovering = true) {
      return util.formatAmount(v, decimal, isCovering);
    }
  }
};
</script>

<style lang="less" scoped>
.top {
  overflow: hidden;

  .left-col {
    float: left;
    width: 60%;
  }

  .img {
    float: right;
  }
}

.visitor-details {
  p {
    color: #aab2bd;
  }

  h5 {
    font-weight: bold;
  }
}

.img {
  width: 40%;
  text-align: center;
  padding: 10px;
  position: relative;
  box-sizing: border-box;

  img {
    width: 100%;
  }
}

.panel-list {
  .panel {
    margin-top: 5px;
    background: #fff;
  }

  .divline {
    padding: 0 0 5px 0;
  }

  .icon-status {
    width: 50px;
    height: 45px;
    position: absolute;
    background-size: 100% auto;
    background-repeat: no-repeat;
    background-position: center center;
    bottom: 36px;
    right: 5px;
  }

  .type-0 {
    background-image: url('../../../images/icon-queryStatus-0.png');
  }

  .type-1 {
    background-image: url('../../../images/icon-queryStatus-1.png');
  }

  .type-2 {
    background-image: url('../../../images/icon-queryStatus-2.png');
  }

  .type-3 {
    background-image: url('../../../images/icon-queryStatus-3.png');
  }

  .type-4 {
    background-image: url('../../../images/icon-queryStatus-4.png');
  }
}

.table {
  margin-top: 5px;

  td {
    background: #f6f6f6;
    height: auto;
    padding: 8px 5px;
    border-bottom: 5px solid #fff;
  }
}

.personal-table {
  td {
    text-align: left;
    word-break: break-all;
  }

  img {
    vertical-align: middle;
  }

  .idcard {
    font-size: 12px;
    padding: 0;
  }
}

.car-table {
  td {
    text-align: center;

    &:first-child {
      text-align: left;
    }

    &:last-child {
      text-align: right;
    }
  }
}

.visit-record {
  padding: 2px 0 0;

  ul {
    li {
      padding: 3px 0;
    }
  }
}
</style>
